<template>
  <div class="">
    <div>
      <form>
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-lg-4">
            <div class="form-group">
              <label for="">Text </label>
              <input class="full-width app-input" />
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-lg-4">
            <div class="form-group">
              <label for="">Text </label>
              <input class="full-width app-input" />
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-lg-4">
            <div class="form-group">
              <label for="">Text </label>
              <input class="full-width app-input" />
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-lg-4">
            <div class="form-group">
              <label for="">Text </label>
              <input class="full-width app-input" />
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-lg-4">
            <div class="form-group">
              <label for="">Text </label>
              <input class="full-width app-input" />
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-lg-4">
            <div class="form-group">
              <label for="">Text </label>
              <input class="full-width app-input" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-lg-4">
            <div class="form-group">
              <button class="app-btn app-btn-blue m-r-10">Search</button>
              <button class="app-btn app-btn-orange">Reset</button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div>
      <div
        class="app-nav-table d-flex justify-content-between p-l-5 p-r-5 m-t-40"
      >
        <div class="app-title">List</div>
        <div class="export-options-container">
          <button class="app-btn app-btn-blue">Add</button>
        </div>
      </div>
      <div class="dataTables_wrapper">
        <div class="table-responsive">
          <table class="table dataTable app-data-table app-colspan app-colspan-5">
            <thead>
              <tr>
                <th class="app-bg-blue-3">No.</th>
                <th class="app-bg-blue-3">Name</th>
                <th class="app-bg-blue-3">Email address</th>
                <th class="app-bg-blue-3">Authority</th>
                <th class="app-bg-blue-3">Status</th>
                <th class="app-bg-blue-3 cell-fit-content">Edit</th>
                <th class="app-bg-blue-3 cell-fit-content">Resent Active URL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="app-align-middle app-bg-light-gray">1</td>
                <td class="app-align-middle app-bg-light-gray">hoangnd4</td>
                <td class="app-align-middle app-bg-light-gray">
                  hoangnd@thefirstone11.jp
                </td>
                <td class="app-align-middle app-bg-light-gray">Admin</td>
                <td class="app-align-middle app-bg-light-gray">Active</td>
                <td class="app-align-middle text-center app-p-none">
                  <button class="app-btn app-btn-blue">Edit</button>
                </td>
                <!---->
                <td class="app-align-middle text-center app-p-none">
                  <button class="app-btn app-btn-orange">Delete</button>
                </td>
              </tr>
              <tr>
                <td class="app-align-middle app-bg-light-gray">1</td>
                <td class="app-align-middle app-bg-light-gray">hoangnd4</td>
                <td class="app-align-middle app-bg-light-gray">
                  hoangnd@thefirstone11.jp
                </td>
                <td class="app-align-middle app-bg-light-gray">Admin</td>
                <td class="app-align-middle app-bg-light-gray">Active</td>
                <td
                  class="app-align-middle text-center app-p-none cell-fit-content"
                >
                  <button class="app-btn app-btn-blue">Edit</button>
                </td>
                <!---->
                <td
                  class="app-align-middle text-center app-p-none cell-fit-content"
                >
                  <button class="app-btn app-btn-orange">Delete</button>
                </td>
              </tr>
              <tr>
                <td class="app-align-middle app-bg-light-gray">1</td>
                <td class="app-align-middle app-bg-light-gray">hoangnd4</td>
                <td class="app-align-middle app-bg-light-gray">
                  hoangnd@thefirstone11.jp
                </td>
                <td class="app-align-middle app-bg-light-gray">Admin</td>
                <td class="app-align-middle app-bg-light-gray">Active</td>
                <td class="app-align-middle text-center app-p-none">
                  <button class="app-btn app-btn-blue">Edit</button>
                </td>
                <!---->
                <td class="app-align-middle text-center app-p-none">
                  <button class="app-btn app-btn-orange">Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="app-table-footer p-t-30 p-b-30">
            <div class="app-page-number">
              <div class="app-title">Number of records/page</div>
              <select class="app-input m-l-10">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
              </select>
            </div>
            <div class="dataTables_paginate app-paginate-container">
              <ul data-v-30f1b2b3="" style="">
                <!---->
                <li
                  data-v-30f1b2b3=""
                  class="
                    paginate-button-prev
                    disabled
                    paginate-button
                    disabled
                    paginate-break
                  "
                >
                  <a data-v-30f1b2b3="">&lt;</a>
                </li>
                <li data-v-30f1b2b3="" class="paginate-button active">
                  <a data-v-30f1b2b3="" tabindex="0">1</a>
                </li>
                <li data-v-30f1b2b3="" class="paginate-button">
                  <a data-v-30f1b2b3="" tabindex="0">2</a>
                </li>
                <li data-v-30f1b2b3="" class="paginate-button-next">
                  <a data-v-30f1b2b3="" tabindex="0">&gt;</a>
                </li>
              </ul>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListPage",
};
</script>
